$breakpoints: (
	small: 0,
	medium: 667px,
	large: 1030px,
	xlarge: 1300px,
	xxlarge: 2000px
);
$breakpoint-classes: (small medium large xlarge);
@import 'tisa-sans-pro';

$global-width:1300px;

$signly-yellow: #fecc00;
$signly-blue: #363457;
$signly-light-blue: #7bdef1;
$signly-grey: #ede4e8;
$signly-type: #ede4e8;
$signly-type-dark: $signly-blue;
$signly-background: #444;

//$button-background-hover: #aa4444;
$primary-color: $signly-blue; //blue
$secondary-color: $signly-yellow;
$nav-font-color: #010000;
$anchor-font-color: $signly-blue;
$default-text: $signly-type;
$global-radius: 100vw;
$body-font-family: 'Tisa Sans Pro', sans-serif;
$body-font-color: $signly-type;
$header-font-family: 'Tisa Sans Pro', sans-serif;
$header-color: $signly-yellow;
$anchor-font-color-hover: darken($signly-blue, 20);
$body-background: $signly-background;
$titlebar-color: $signly-type-dark;
$titlebar-icon-color: $signly-type-dark;
$button-color: $signly-type-dark;
$button-color-alt: $signly-type;
$button-background: $signly-yellow;
$button-background-hover: scale-color($button-background, $lightness: 35%);
$offcanvas-background: $signly-blue;
$offcanvas-exit-background: rgba(black, 0.3);

/*$input-background: $signly-yellow;
$input-background-focus: $signly-blue;
$input-background-disabled: $signly-grey;
$select-background: $signly-yellow;*/
$input-border: 1px solid $signly-grey;
$input-radius: 30px;
$input-border-focus: 1px solid $signly-grey;
$input-placeholder-color: #777;

$form-label-color: $signly-yellow;
$form-label-font-size: 1.4rem;

$table-background: $signly-grey;
$breadcrumbs-item-color-current: white;

$foundation-palette: (
  primary: $signly-yellow,
  secondary: $signly-blue,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
);

@import "~foundation-sites/scss/foundation";
@include foundation-everything();
@import "~motion-ui/src/motion-ui.scss";
@include motion-ui-transitions;
@include motion-ui-animations;

$fa-font-path: './fonts';
@import "~font-awesome/scss/font-awesome";

//@import "~select2/src/scss/core";
//@import "jquery.typeahead.scss";