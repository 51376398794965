@import "components/settings";

h1 {
  	padding: 0.5rem 1.5rem;
    font-size: 1.5rem;
    line-height: 1.2em;
	margin-bottom: 0;

    @include breakpoint(large) {
      font-size: 2rem;
    }
}

.mobile-nav-bar {
	background-color: $signly-yellow;
	a {
		color: $signly-blue;
	}
}

$mobile-app-dashboard-height: 60vh;
$mobile-app-dashboard-item-color: white;
$mobile-app-dashboard-img-size: rem-calc(50);
$mobile-app-dashboard-bg: #bfbfbf;

.mobile-app-dashboard-header {
  display: flex;
  height: 100vh - $mobile-app-dashboard-height;
  align-items: center;
  justify-content: center;
  background: url('https://images.pexels.com/photos/59519/pexels-photo-59519.jpeg?h=350&auto=compress&cs=tinysrgb') center center no-repeat;

  h1 {
    color: $white;
    text-align: center;
    font-size: 22px;
    background: rgba($white, 0.2);
  }
}

.mobile-app-dashboard {
  background-color: $mobile-app-dashboard-bg;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  height: $mobile-app-dashboard-height;

  a {
    flex-basis: 50%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    color: $mobile-app-dashboard-item-color;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $mobile-app-dashboard-item-color;

    &:nth-child(2n-1){
      border-right: 1px solid $mobile-app-dashboard-item-color;
    }
  }

  .mobile-app-dashboard-inner {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    img,
    svg,
    i {
      max-width: $mobile-app-dashboard-img-size;
      max-height: $mobile-app-dashboard-img-size;
	  font-size: $mobile-app-dashboard-img-size;
	  color: $primary-color;
    }
  }
}

.header-font {
  font-family: $header-font-family;
}

label {
  text-align: center;
}

.typeahead__cancel-button { display: none; }

.body-text {
  font-family: $body-font-family;
}

video#video {
  width: 100vw; height: auto; max-height: calc(100vh - 240px); max-width: 100vw;
}

.items .item-button {
  display: block; width: 100%;
}
footer {
  min-height: 3rem;
  padding-top: 1rem;
  font-size: 0.8rem;
  color: #888;
}
.main-content {
  min-height: calc(100vh - 4rem);
}
#content-area {
  padding-top: 1.5rem;
}


.input-group.radius-input-left input {
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  padding-left: 1rem;
}

.vue3-autocomplete-container .vue3-results-item {
  color: $signly-type-dark;
}